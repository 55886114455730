import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../components/LoadingIndicator';
import { useUser } from '../UserContext';  // Access the user context

// Define styled components
const Container = styled.div`
  width: 80%;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
  margin-top: 60px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  text-align: left;
`;

const TextDiv = styled.div`
  font-size: 16px;
  margin: 10px 0;
  text-align: left;
  width: 100%;
  font-family: Arial, sans-serif;

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #5c67f2;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin: 20px auto;
  display: block;
  &:hover {
    background-color: #4b55c3;
  }
`;

const GradedEssayPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();  // Get the essay id from the URL params
  const [savedEssay, setSavedEssay] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // For loading state
  const user = useUser(); 

  useEffect(() => {
    if (id) {
      setIsLoading(true); // Start loading
      fetch(`/essay?id=${id}&user_id=${user.email}`)
        .then(response => response.json())
        .then(data => {
          setIsLoading(false);  // Stop loading
          setSavedEssay(data);  // Set the fetched data to state
        })
        .catch(error => {
          setIsLoading(false);  // Stop loading
          console.error('Error fetching graded essay:', error);
          toast.error('Error fetching essay. Please try again!');
        });
    }
  }, [id, user.email]);  // Re-fetch if the `id` changes

  const handleTryAgain = () => {
    navigate('/');
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator /> // Show the loading indicator
      ) : (
        <>
          <Container>
            <Section>
              <SectionTitle>Score</SectionTitle>
              <TextDiv><pre>{savedEssay?.score || ""}</pre></TextDiv>
            </Section>
          </Container>

          <Button onClick={handleTryAgain}>Try Again</Button>
        </>
      )}
    </div>
  );
};

export default GradedEssayPage;
