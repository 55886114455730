import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import EssayPage from './pages/EssayPage';
import GradedEssayPage from './pages/GradedEssayPage';
import StudentDashboard from './pages/StudentDashboard';
import Practice from './pages/Practice';
import NotFound from './pages/NotFoundPage';
import About from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import MenuDrawer from './components/MenuDrawer';
import LoadingPage from './pages/LoadingPage';
import ErrorPopup from './components/ErrorPopup';
import SubmodulePage from './pages/SubmodulePage';
import { trackEvent } from './util.js';
import { useAuth0 } from '@auth0/auth0-react';
import { UserProvider } from './UserContext'; // Import UserContext provider
import { toast } from 'react-toastify';

const App = () => {
  const { isAuthenticated, isLoading, user, getIdTokenClaims, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const trackUserEvent = async () => {
      if (isAuthenticated && user) {
        const claims = await getIdTokenClaims();
        const isSignup = claims['https://trykeating.com/signup'];


      // If it's a signup, call the /signup endpoint
      if (isSignup) {
        try {
          const response = await fetch('/signup', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: user.email,  // Send the user email to the signup endpoint
            }),
          });

          if (!response.ok) {
            toast.error('Error signing up user');
          }
        } catch (error) {
          toast.error('Error during signup request', error);
        }
      }

        const eventData = {
          event_name: isSignup ? 'Signup' : 'Login',
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: window.location.href,
          user_data: {
            em: user.email,
            client_user_agent: navigator.userAgent,
          },
        };
        trackEvent(eventData);
      }
    };
    trackUserEvent();
  }, [isAuthenticated, user, getIdTokenClaims]);

  useEffect(() => {

    // Force redirect to login if not authenticated
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();  // Force user to login
    }


    if (isAuthenticated) {
      const eventData = {
        event_name: 'Login',
        event_time: Math.floor(Date.now() / 1000),
        action_source: 'website',
        event_source_url: window.location.href,
        user_data: {
          em: user?.email, // Ensure user.email is available before accessing it
          client_user_agent: navigator.userAgent,
        },
      };
      trackEvent(eventData);
    }
  }, [isAuthenticated, user, isLoading, loginWithRedirect]);

  const [gradedEssay, setGradedEssay] = React.useState(null);

  const handleGradingSuccess = (essay) => {
    setGradedEssay(essay);
  };



  // Only render the UserProvider when the user is available
  if (isLoading || !user) {
    return <LoadingPage />;
  }

  return (
    <UserProvider user={user}> {/* Ensure user is passed to UserProvider */}
      <ErrorPopup />
      <Router>
        {isAuthenticated && <MenuDrawer />}
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<StudentDashboard />} />
              <Route path="/test" element={<EssayPage onSuccess={handleGradingSuccess} />} />
              <Route path="/graded/:id" element={<GradedEssayPage gradedEssay={gradedEssay} />} />
              <Route path="/practice" element={<Practice />} />
              <Route path="/practice/:submoduleId/:submoduleName" element={<SubmodulePage />} />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <Route path="/" element={<LoginPage />} />
          )}
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
