import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast} from 'react-toastify';
import Confetti from 'react-confetti';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../UserContext';  // Access the user context
import CircularProgress from '@mui/material/CircularProgress';  // Import Material UI CircularProgress

const apiUrl = `${process.env.REACT_APP_API_URL}`;

// Styled Components
const Container = styled.div`
  width: 60%;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 60px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${(props) => props.progress || 0}%;
  border-radius: 5px;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
`;

const ProgressText = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
`;

const QuestionText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const DisputeButton = styled.button`
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const ExplanationBox = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
`;

const SubmodulePage = () => {
  const { submoduleId, submoduleName} = useParams();
  const submodule = submoduleName 
  .replace(/-/g, ' ')  // Replace kebab with spaces
  .replace(/\b\w/g, (char) => char.toUpperCase());  // Capitalize the first letter of each word

  const navigate = useNavigate();
  const user = useUser();  // Access the user context (e.g., user email)
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [gradingLoading, setGradingLoading] = useState(false);  // State for grading loading
  const [is_answer_correct, setIsAnswerCorrect] = useState(null);  // Track if the answer is correct
  const [is_answered, setIsAnswered] = useState(false);  // Track if the answer has been submitted
  const [explanation, setExplanation] = useState('');  // Explanation text if the answer is wrong
  const [progressBarPercentage, setProgressBarPercentage] = useState(0); // ProgressBar
  
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${apiUrl}/practice/${submoduleId}?user_id=${user.email}`);

        let data = { questions: [], current_question_index: 0 };

        if (response.ok) {
          data = await response.json(); // Parse JSON if the response is valid
        } else {
          toast.error('Failed to load questions. Using default values.');
        }

        setQuestions(data.questions || []);
        setCurrentQuestionIndex(data.current_question_index || 0);
        setProgressBarPercentage(data['completion_percentage']);
      } catch (error) {
        toast.error('Error fetching questions. Please check your connection.');
        setQuestions([]); // Default fallback
        setCurrentQuestionIndex(0); // Default fallback
      } finally {
        setLoading(false); // Stop the loading state
      }
    };
    fetchQuestions();
  }, [submoduleId, user.email]);

  const handleSubmit = async () => {
    if (!user?.email) {
      toast.error('User email is not available.');
      return;
    }

    setGradingLoading(true);  // Start loading state for grading

    try {
      const response = await fetch(`${apiUrl}/grade/question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.email,
          submodule: submoduleId,
          question: questions[currentQuestionIndex],
          answer: answer,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit answer.');
      }

      const data = await response.json();

      if (!data.success) {
        // If success is false, show an error toast and don't move to the next question
        toast.error(data.message);
        setIsAnswerCorrect(false);  // Set answer as incorrect
        setExplanation(data.message);  // Set the explanation
        setIsAnswered(true);  // Mark the answer as submitted
        setGradingLoading(false);  // Stop loading for grading
        return;
      }

      setProgressBarPercentage(data['completion_percentage']);

      if (data.module_completed) {
        setShowConfetti(true);
        toast.success('Module complete! 🎉');
        setTimeout(() => {
          setShowConfetti(false);
          navigate('/practice');
        }, 3000);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex+1);
        toast.success('Answer submitted successfully!');
        setIsAnswerCorrect(true);  // Set answer as correct
        setExplanation('');  // Clear explanation
      }

      setAnswer('');
      setGradingLoading(false);  // Stop loading for grading
    } catch (error) {
      toast.error('Failed to submit answer.');
      setGradingLoading(false);  // Stop loading for grading
    }
  };

  const handleDispute = () => {
    toast.info('Your feedback has been recorded. Thank you!');
  };

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (questions.length === 0) {
    return (
      <Container>
        <Title>{submodule}</Title>
        <Subtitle>No questions available for this submodule.</Subtitle>
      </Container>
    );
  }

  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <Container>
      {showConfetti && <Confetti />}
      <Title>{submodule}</Title>
      <Subtitle>Question {currentQuestionIndex + 1} of {questions.length}</Subtitle>

      <ProgressBarContainer>
        <ProgressBar progress={progressBarPercentage} />
      </ProgressBarContainer>

      <ProgressText>{Math.round(progressBarPercentage)}% Complete</ProgressText>

      <QuestionText>{questions[currentQuestionIndex]?.prompt}</QuestionText>
      <TextArea
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Type your answer here..."
      />
      <ButtonGroup>
        <DisputeButton
          onClick={handleDispute}
          disabled={!is_answered || is_answer_correct} // Disable if not answered or if answer is correct
        >
          Dispute Answer
        </DisputeButton>
        <SubmitButton
          onClick={handleSubmit}
          disabled={!answer.trim()}
        >
          {isLastQuestion ? 'Complete Module!' : 'Submit Answer'}
        </SubmitButton>
      </ButtonGroup>

      {gradingLoading && (
        <div style={{ marginTop: '20px' }}>
          <CircularProgress />
          <p>Submitting your answer...</p>
        </div>
      )}

      {/* Explanation Box (Only visible when answer is wrong) */}
      {is_answer_correct === false && explanation && (
        <ExplanationBox>
          <p>{explanation}</p>
        </ExplanationBox>
      )}
    </Container>
  );
};

export default SubmodulePage;
