import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useUser } from '../UserContext';  // Access the user context
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Helper function to convert strings to snake case
const toKebabCase = (str) =>
  str.toLowerCase().replace(/[\s_]+/g, '-').replace(/[^a-z0-9-]/g, '');

const StyledContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
  margin-top: 60px;
`;

const StyledModule = styled(Box)`
  width: 100%;
  max-width: 800px;
  padding: 16px;
  margin: 16px auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const SubmoduleContainer = styled(Box)`
  width: 100%;
  margin-top: 16px;
`;

const SubmoduleRow = styled(Box)`
  margin: 12px 0; /* Add spacing between submodules */
  padding: 12px 16px;
  border: 1px solid #ddd; /* Add a border around each submodule */
  border-radius: 8px;
  display: flex;
  justify-content: space-between; /* Align text left, progress right */
  align-items: center;
  background-color: #f9f9f9;
  transition: background-color 0.3s, transform 0.2s;

  /* Hover effect */
  &:hover {
    background-color: #f1f1f1;
    transform: scale(1.02); /* Slight enlargement on hover */
  }

  a {
    text-decoration: none;
    color: #333;
    font-size: 18px; /* Larger font for better visibility */
    font-weight: 500;
    width: 70%; /* Allow space for progress bar */
    text-align: left; /* Align text to the left */
  }

  .progress-bar {
    width: 25%; /* Adjust to balance with the text */
  }
`;

const Practice = () => {
  const user = useUser();  // Access the user context (e.g., user email)
  const [expandedModule, setExpandedModule] = useState(null);
  const [curriculum, setCurriculum] = useState([]);  // Holds the fetched curriculum
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.email) {
      // Fetch the curriculum data only if user email is available
      const fetchCurriculumData = async () => {
        try {
          const response = await fetch(`/curriculum?user_id=${user.email}`);
          const data = await response.json();
          setCurriculum(data.curriculum);  // The data should now contain the submodules and progress
          setLoading(false);
        } catch (error) {
          toast.error('Error fetching curriculum data');
          setLoading(false);
        }
      };
      fetchCurriculumData();
    }
  }, [user?.email]);

  const handleModuleClick = (category) => {
    setExpandedModule((prev) => (prev === category ? null : category));
  };

  if (loading) {
    return (
      <StyledContainer>
        <CircularProgress />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <CssBaseline />
      <Typography variant="h4" gutterBottom>
        Curriculum
      </Typography>
      {curriculum.map((module, index) => {
        const isExpanded = expandedModule === module.module_name;

        return (
          <StyledModule key={index} onClick={() => handleModuleClick(module.module_name)}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                {isExpanded ? <ExpandMore /> : <ChevronRight />}
                <Typography variant="h5" style={{ marginLeft: '8px' }}>
                  {module.module_name}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={module.submodules.reduce((acc, submodule) => {
                  // Calculate progress for each submodule using the completion_percentage
                  return acc + submodule.completion_percentage;
                }, 0) / module.submodules.length}  // Average progress for the module
                style={{ width: '30%' }}
              />
            </Box>
            {isExpanded && (
              <SubmoduleContainer>
                {module.submodules.map((submodule, idx) => {
                  return (
                    <SubmoduleRow key={idx}>
                      <Link
                        to={`/practice/${submodule.id}/${toKebabCase(submodule.name)}`}
                      >
                        {submodule.name}
                      </Link>
                      <LinearProgress
                        className="progress-bar"
                        variant="determinate"
                        value={submodule.completion_percentage}  // Use the completion_percentage for each submodule
                      />
                    </SubmoduleRow>
                  );
                })}
              </SubmoduleContainer>
            )}
          </StyledModule>
        );
      })}
    </StyledContainer>
  );
};

export default Practice;