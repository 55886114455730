import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline } from '@mui/material';
import Header from './Header';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  const { logout } = useAuth0();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Add Dashboard to drawerItems
  const drawerItems = [
    { text: 'Progress', path: '/' }, // Home page route
    { text: 'Test Mode', path: '/test' },
    { text: 'Practice', path: '/practice' },
    { text: 'About', path: '/about' },
    { text: 'Logout', action: () => logout({ returnTo: window.location.origin }) },
  ];

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 'bold',
  }));

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CssBaseline />
      <Header toggleDrawer={toggleDrawer}></Header>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            width: '240px',
            background: 'linear-gradient(to right, #f2e8df, #f9f4e8)',
          },
        }}
      >
        <List>
          {drawerItems.map((item, index) => (
            <ListItem
              button
              key={index}
              component={item.path ? Link : 'button'}
              to={item.path}
              onClick={item.action || toggleDrawer(false)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <StyledListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default LoginPage;
