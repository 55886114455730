import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, MenuItem, Select, Button } from '@mui/material';
import styled from 'styled-components';
import { Assignment, School } from '@mui/icons-material';

const PageContainer = styled(Box)`
  width: 80%;
  margin: auto;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  overflow-y: auto; /* Enable scrolling inside the container if content overflows */
  margin-top: 60px; /* Add space on the top to avoid overlap with the header */

`;

const TopRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
`;

const BandContainer = styled(Box)`
  flex: 1;
  padding: 20px;
  margin: 0 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BottomRow = styled(Box)`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const ModeContainer = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 150px;
  color: white;
  /* Temporary fix to ensure background color isn't overridden by Material-UI */
  background-color: #f9f9f9 !important; /* Force the background color */
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-transform: none;
  transition: background-color 0.3s, transform 0.2s;

  background-color: #e8e8f2; /* Slightly distinct color */
  &:hover {
    background-color: #4b55c3; /* Slightly darker shade on hover */
    transform: scale(1.02); /* Slight enlargement on hover */
  }
`;

const StudentDashboard = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  // Extract first name or default
  const firstName = user?.given_name || 'User';

  // Current and Target Band state
  const [currentBand, setCurrentBand] = useState('B1');
  const [targetBand, setTargetBand] = useState('C1');

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <PageContainer>
      <Typography variant="h4" gutterBottom>
        Welcome{firstName ? `, ${firstName}` : ''}!
      </Typography>
      <TopRow>
        <BandContainer>
          <Typography variant="h6">Current Band</Typography>
          <Select
            value={currentBand}
            onChange={(e) => setCurrentBand(e.target.value)}
            fullWidth
          >
            {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((band) => (
              <MenuItem key={band} value={band}>
                {band}
              </MenuItem>
            ))}
          </Select>
        </BandContainer>
        <BandContainer>
          <Typography variant="h6">Target Band</Typography>
          <Select
            value={targetBand}
            onChange={(e) => setTargetBand(e.target.value)}
            fullWidth
          >
            {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((band) => (
              <MenuItem key={band} value={band}>
                {band}
              </MenuItem>
            ))}
          </Select>
        </BandContainer>
      </TopRow>
      <BottomRow>
        <ModeContainer onClick={() => handleNavigate('/test')}>
          <Assignment fontSize="large" />
          <Typography variant="h6" marginTop={1}>
            Test Mode
          </Typography>
        </ModeContainer>
        <ModeContainer onClick={() => handleNavigate('/practice')}>
          <School fontSize="large" />
          <Typography variant="h6" marginTop={1}>
            Practice Mode
          </Typography>
        </ModeContainer>
      </BottomRow>
    </PageContainer>
  );
};

export default StudentDashboard;
